import React from "react"
import styled, { css } from 'styled-components';
import { layout } from "../shared/styles"

const Wrapper = styled.div`
  display: grid;
  grid: auto/minmax(0,1fr);
  row-gap: ${layout.rowLayoutGapLarge}px;
  
  @media (min-width: ${layout.viewportWidthMedium}px) {
    ${(props) => props.columns == "1,1" && css`
      grid-template-columns: repeat(2, 1fr);
    `}
    
    ${(props) => props.columns ==="1,1,1" && css`
      grid-template-columns: repeat(3, 1fr);
    `}
  }
`;

export default function RowLayout({ children }) {
  return (
    <Wrapper>{children}</Wrapper>
  )
}