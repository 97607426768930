import React from "react"
import styled, { css } from 'styled-components';
import { breakpoint, layout } from "../shared/styles"
import RowLayout from "./RowLayout"

const Wrapper = styled.div`
  display: grid;
  row-gap: ${layout.rowLayoutGapLarge}px;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'flex-start'};
  
  @media (min-width: ${layout.viewportWidthMedium}px) {
    ${(props) => props.columns == "1,1" && css`
      grid-template-columns: repeat(2, 1fr);
    `}
    
    ${(props) => props.columns ==="1,1,1" && css`
      grid-template-columns: repeat(3, 1fr);
    `}
  }
`;

export default function ColumnLayout({ children, columns, alignItems = 'flex-start' }) {
  return (
    <RowLayout>
      <Wrapper columns={columns} alignItems={alignItems}>{children}</Wrapper>
    </RowLayout>
  )
}