import React, { useEffect, useState } from "react"
import styled, { css } from 'styled-components';
import throttle from 'lodash.throttle';

import {
  breakpoint,
  color, layout,
  pageMargins,
  typography
} from "../shared/styles"
import ColumnLayout from "./ColumnLayout"

const Media = styled.div`
`;

const Title = styled.h2`
  font-size: ${typography.size.h2}px;
  font-weight: ${typography.weight.semibold};
  line-height: 39px;
  margin-bottom: 0.5rem;
  @media (min-width: ${breakpoint}px) {
    font-size: ${typography.size.l1}px;
    line-height: 36px;
    margin-bottom: 0.75rem;
  }
`;

const Desc = styled.div`
  font-size: ${typography.size.p2}px;
  line-height: 1.5;
  color: ${color.textSecondary};
  @media (min-width: ${breakpoint}px) {
    font-size: ${typography.size.p1}px;
    line-height: 32px;
  }
`;

const Meta = styled.div`
`;

const Wrapper = styled.div`
  text-align: center;
  
  ${Media} {
    margin: 0 auto;
  }

  @media (min-width: ${layout.viewportWidthMedium}px) {
    text-align: left;
    ${(props) =>
      props.orientation === 'left' &&
      css`
        ${Meta} {
          order: 1;
          padding-right: 0;
          padding-left: ${layout.columnPaddingNormal}px;
        }
        
        ${Media} {
          order: 2;
        }
      `};
    ${(props) =>
      props.orientation === 'right' &&
      css`
        ${Meta} {
          order: 2;
          padding-right: ${layout.columnPaddingNormal}px;
          padding-left: 0;
        }
        
        ${Media} {
          order: 1;
        }
      `};
    }
  }
`;

export default function ValueProp({ orientation, media, title, desc, ...props }) {
  /*
  const [breakpoint, setBreakpoint] = useState(() => window.innerWidth);

  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBreakpoint(window.innerWidth);
    }, 200);
    window.addEventListener('resize', calcInnerWidth);

    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);*/

  return (
    <Wrapper orientation={orientation}>
      <ColumnLayout columns="1,1" alignItems="center">
        <Meta>
          {title && <Title>{title}</Title>}
          {desc && <Desc>{desc}</Desc>}
        </Meta>
        <Media>{media}</Media>
      </ColumnLayout>
    </Wrapper>
  );
}
